import { getFetchMoviesKeys, useFetchMovies } from "@multicines/services";
import { useMoviesStore } from "@multicines/stores";
import { Movie } from "@multicines/types";
import EmptyBillboardSVG from "images/empty-billboard.svg";
import { useRouter } from "next/router";
import React from "react";
import Carousel from "react-multi-carousel";
import { useQueryClient } from "react-query";

import { responsive } from "./BillboardCarousel.helpers";
import Styles from "./BillboardCarousel.styles";
import { BillboardCarouselProps as Props } from "./BillboardCarousel.types";
import Button from "../../global/Button/Button";
import Divider from "../../global/Divider/Divider";
import EmptyState from "../../global/EmptyState/EmptyState";
import ErrorState from "../../global/ErrorState/ErrorState";
import MovieCard from "../../global/MovieCard/MovieCard";
import BillboardPlaceholder from "components/Billboard/BillboardPlaceholder/BillboardPlaceholder";
import useI18n from "hooks/useI18n";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";

const BillboardCarousel: React.FC<Props> = props => {
  const { className } = props;
  const { push } = useRouter();
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const setSelectedMovie = useMoviesStore(store => store.setSelectedMovie);
  const t = useI18n();
  const queryClient = useQueryClient();
  const selectedStore = useStoresStore(store => store.selectedStore);
  const { data: movies, status } = useFetchMovies(
    { isAnonymous, uid },
    selectedStore
  );
  const [ticketsStore] = selectedStore ?? [];
  const { externalId } = ticketsStore?.additionalInfo ?? {};
  const noMovies = !!movies && movies.length === 0;

  const refetchMoviesQuery = () => {
    queryClient.refetchQueries(getFetchMoviesKeys(externalId));
  };

  const renderItem = (movie: Movie, index: number) => {
    const pressHandler = () => setSelectedMovie(movie);
    return (
      <MovieCard
        key={index}
        className="BillboardCarousel__movies"
        movie={movie}
        onClick={pressHandler}
      />
    );
  };

  const renderStates = () => {
    if (status === "error") return <ErrorState onClick={refetchMoviesQuery} />;
    if (status === "loading" || status === "idle") {
      return (
        <BillboardPlaceholder className="BillboardCarousel__placeholder" />
      );
    }
    if (status === "success" && noMovies) {
      return (
        <EmptyState
          className="BillboardCarousel__empty"
          icon={<EmptyBillboardSVG />}
          title={t.movie.emptyBillboard}
          description={t.movie.emptyBillboardMessage}
        />
      );
    }
    if (status === "success") {
      return (
        <Carousel
          swipeable
          draggable
          additionalTransfrom={0}
          autoPlaySpeed={3000}
          showDots={false}
          arrows={false}
          responsive={responsive}
          infinite
          keyBoardControl
          containerClass="BillboardCarousel__container"
          itemClass="BillboardCarousel__item"
          partialVisible={false}
        >
          {movies?.map((movie, index) => {
            return renderItem(movie, index);
          })}
        </Carousel>
      );
    }

    return null;
  };

  return (
    <Styles className={`BillboardCarousel ${className}`} empty={noMovies}>
      <p className="BillboardCarousel__hello">{t.greetings.discover}</p>
      {renderStates()}
      <div className="BillboardCarousel__divider">
        <Divider className="BillboardCarousel__line" />
        <Button
          mode="SECONDARY"
          className="BillboardCarousel__button"
          onClick={() => push("/billboard")}
        >
          {t.greetings.billboard}
        </Button>
        <Divider className="BillboardCarousel__line" />
      </div>
    </Styles>
  );
};

BillboardCarousel.defaultProps = {
  className: ""
};

export default BillboardCarousel;
